html, body, #root {
  height: 100%;
}

.App {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
